import { publicCDN } from "config"
import range from "lodash/range"

export const backs = ["blue", "gray", "green", "red"]

export const fronts = ["black", "blue", "gray", "green", "red"]

const defaultConfig = {
  turnButton: {
    bgImage: `url(${publicCDN}/img/icon/icon_tap_card.png)`,
    bgImageDark: `url(${publicCDN}/img/icon/icon_tap_card_dark.png)`,
  },
  bgImageStyle: {
    backgroundImage: `url(${publicCDN}/img/bg-raster.svg)`,
    mixBlendMode: "overlay",
  },
  homepage: {
    leftAgentImage: `${publicCDN}/homepage/agent_female.png`,
    rightAgentImage: `${publicCDN}/homepage/agent_male.png`,
  },
}

export const themeConfig = {
  classic: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 9),
      red: range(0, 9),
      green: range(0, 20),
      gray: range(0, 6),
      black: [],
    },
    charSize: {
      landscape: { width: 134, height: 120 },
      portrait: { width: 59, height: 53 },
    },
  },
  ljo: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 9),
      red: range(0, 9),
      green: range(0, 20),
      gray: range(0, 6),
      black: [],
    },
    charSize: {
      landscape: { width: 134, height: 120 },
      portrait: { width: 59, height: 53 },
    },
  },
  galaxytrucker: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 12),
      red: range(0, 12),
      green: range(0, 20),
      gray: range(0, 10),
      black: [],
    },
    charSize: {
      landscape: { width: 212, height: 135 },
      portrait: { width: 94, height: 60 },
    },
  },
  xmas: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 9),
      red: range(0, 9),
      green: range(0, 20),
      gray: range(0, 6),
      black: [],
    },
    charSize: {
      landscape: { width: 134, height: 120 },
      portrait: { width: 59, height: 53 },
    },
  },
  julien: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 0),
      red: range(0, 0),
      green: range(0, 0),
      gray: range(0, 6),
      black: [],
    },
    charSize: {
      landscape: { width: 134, height: 120 },
      portrait: { width: 59, height: 53 },
    },
  },
  kara: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 0),
      red: range(0, 0),
      green: range(0, 0),
      gray: range(0, 6),
      black: [],
    },
    charSize: {
      landscape: { width: 134, height: 120 },
      portrait: { width: 59, height: 53 },
    },
  },
  streamers: {
    _version: 2,
    ...defaultConfig,
    chars: {
      blue: 14,
      red: 14,
      green: 14,
      neutral: 5,
      black: 1,
    },
    charSize: {
      landscape: { width: 145, height: 120 },
      portrait: { width: 64, height: 53 },
    },
  },
  chrispy: {
    _version: 2,
    ...defaultConfig,
    chars: {
      blue: 15,
      red: 15,
      green: 15,
      neutral: 5,
      black: 1,
    },
    charSize: {
      landscape: { width: 145, height: 125 },
      portrait: { width: 64, height: 53 },
    },
  },
  halloween: {
    _version: 1,
    ...defaultConfig,
    chars: {
      blue: range(0, 6),
      red: range(0, 6),
      green: range(0, 20),
      gray: range(0, 5),
      black: [],
    },
    charSize: {
      landscape: { width: 134, height: 120 },
      portrait: { width: 59, height: 53 },
    },

    turnButton: {
      bgImage: `url(${publicCDN}/theme/halloween/zombie_hand.png)`,
      bgImageDark: `url(${publicCDN}/theme/halloween/zombie_dark_hand.png)`,
    },

    bgImageStyle: {
      backgroundImage: `url('${publicCDN}/theme/halloween/bg-raster.png')`,
      mixBlendMode: "overlay",
      opacity: 0.2,
    },

    homepage: {
      leftAgentImage: `${publicCDN}/theme/halloween/homepage/halloween_agent_female.png`,
      rightAgentImage: `${publicCDN}/theme/halloween/homepage/halloween_agent_male.png`,
    },
  },
}
