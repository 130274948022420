// @ts-nocheck
import * as React from "react"
import Head from "next/head"
import Router from "next/router"
import initReactFastclick from "react-fastclick"
import * as Sentry from "@sentry/react"
import "../../tailwind.css"

import * as gtag from "analytics"
import { useLocalizationStore } from "features/Localization/useLocalization"
import { useThemeState } from "features/Theme/useThemeState"
import { useLobbyStore } from "hooks/lobby/useLobbyService"
import isEqual from "react-fast-compare"
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ErrorBoundaryFallback } from "components/ErrorBoundaryFallback"

Router.events.on("routeChangeComplete", (url) => gtag.pageview(url))

const MyApp = ({ Component, pageProps }: any) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { refetchOnWindowFocus: false } },
      })
  )

  const themeState = useThemeState((state) => state, isEqual)
  const lobbyStore = useLobbyStore((state) => state, isEqual)

  React.useEffect(() => {
    const appVersion = "2.2.0"

    initReactFastclick()

    localStorage.setItem("appVersion", appVersion)
    window["appVersion"] = appVersion

    useLocalizationStore.getState().init()

    themeState.init()
    lobbyStore.checkMigration()
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/gjc1prl.css?v=1" />
        {process.env.NODE_ENV === "production" && (
          <>
            {process.env.NEXT_PUBLIC_STAGE != "true" ? (
              <>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', '${gtag.GA_TRACKING_ID}', {
                            page_path: window.location.pathname,
                            });
                        `,
                  }}
                />
              </>
            ) : null}

            <script
              async
              defer
              data-domain="codenames.game"
              data-api="/api/event"
              src="/js/script.js"
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                           window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
                        `,
              }}
            ></script>
          </>
        )}
      </Head>

      <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default MyApp
