import { CenteredLayout } from "layout/Centered.layout"
import { Button } from "./Button"

export const ErrorBoundaryFallback = ({
  error,
  componentStack,
  resetError,
}) => {
  return (
    <CenteredLayout>
      <main>
        <h1 className={"px-2 text-2xl font-bold mb-4"}>
          Ooops, something went wrong!
        </h1>
        <p className="mb-4">
          Sorry, this is not meant to happen. The mistake was logged and we will
          work on fixing it.
        </p>
        <Button onClick={resetError}>Reloading a page might help</Button>
      </main>
    </CenteredLayout>
  )
}
