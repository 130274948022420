import create from "zustand"
import { persist } from "zustand/middleware"
import produce from "immer"
import queryString from "query-string"

const defaultState = {
  darkMode: true,
  enableTheme: true,
  theme: "classic",
  currentEvent: "classic",
  themeList: [
    "classic",
    "galaxytrucker",
    "xmas",
    "julien",
    "kara",
    "streamers",
    "chrispy",
    "ljo",
    "halloween",
  ],
}

const store = (set, get) => ({
  ...defaultState,
  init: () => {
    // detect
    // window.matchMedia("(prefers-color-scheme: dark)").matches

    if (get().darkMode) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }

    const query = queryString.parse(location.search)
    if (query.theme) {
      get().setEventTheme(query.theme)
    }
  },
  toggleDarkMode: () => {
    get().update((state) => {
      state.darkMode = !get().darkMode
    })
    get().init()
  },
  toggleEventTheme: () => {
    get().update((state) => {
      state.enableTheme = !get().enableTheme
      if (get().enableTheme) {
        state.theme = "classic"
      } else {
        state.theme = get().currentEvent
      }
    })
  },
  setEventTheme: (theme) => {
    get().update((state) => {
      state.theme = get().themeList.indexOf(theme) !== -1 ? theme : "classic"
      console.log("theme", theme, state.theme)
    })
  },
  update: (fn) => set(produce(fn)),
})

export const useThemeState = create(
  persist(store, {
    name: "cno-theme",
    version: 12,
    migrate: (oldState, oldVersion: number) => {
      return produce(oldState, (nextState) => {
        // enable themes for each event even when disabled before
        nextState.enableTheme = defaultState.enableTheme
        nextState.theme = defaultState.currentEvent
        nextState.themeList = defaultState.themeList
        nextState.currentEvent = defaultState.currentEvent
        if (nextState.currentEvent === "halloween") {
        }
        nextState.darkMode = true
      }) as any
    },
  })
)

export const isDarkMode = () => {
  if (typeof window === "undefined") {
    return false
  }

  try {
    return (
      useThemeState((state) => state.darkMode) &&
      document.documentElement.classList.contains("dark")
    )
  } catch (e) {
    return false
  }
}
