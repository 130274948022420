import Cookies from "js-cookie"

import create from "zustand"
import produce from "immer"

import en from "localisation/en.json"
import cs from "localisation/cs.json"
import de from "localisation/de.json"
import ja from "localisation/ja.json"
import uk from "localisation/uk.json"
import fr from "localisation/fr.json"
import ro from "localisation/ro.json"
import sr from "localisation/sr-Latn-RS.json"
import it from "localisation/it.json"
import ko from "localisation/ko.json"
import he from "localisation/he.json"
import id from "localisation/id.json"
import pl from "localisation/pl.json"
import nl from "localisation/nl.json"
import ru from "localisation/ru.json"

import dk from "localisation/da.json"
import sv from "localisation/sv.json"
import tr from "localisation/tr.json"
import pt from "localisation/pt-PT.json"
import ptBr from "localisation/pt-BR.json"
import eo from "localisation/eo.json"
import ca from "localisation/ca.json"
import mk from "localisation/mk.json"
import et from "localisation/et.json"
import no from "localisation/no.json"
import lt from "localisation/lt.json"
import be from "localisation/be.json"

let enabledTranslations: any = {
  en,
  cs,
  de,
  fr,
  ja,
  it,
  pl,
  uk,
  he,
  sr,
  ko,
  ro,
  id,
  tr,
  dk,
  pt,
  ca,
  sv,
  mk,
  et,
  eo,
  ptBr,
  be,
}
if (
  process.env.NEXT_PUBLIC_STAGE == "true" ||
  process.env.NODE_ENV === "development"
) {
  enabledTranslations = {
    ...enabledTranslations,
    nl,
    ru,
    no,
    lt,
  }
}

const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api)

const store = (set, get) => ({
  current: "en",
  translations: enabledTranslations,
  languages: [
    "en",
    "cs",
    "de",
    "it",
    "ja",
    "uk",
    "nl",
    "pl",
    "ro",
    "pt",
    "es",
    "no",
    "fi",
    "se",
    "dk",
    "ca",
    "hu",
    "ko",
    "sk",
    "fr",
    "is",
    "gr",
    "ru",
    "fl",
    "bg",
    "pt-br",
    "lv",
    "lt",
    "et",
    "he",
    "sl",
    "sr",
    "eo",
    "zh",
    "id",
    "th",
    "hr",
    "tr",
    "ar",
    "af",
    "ar-lb",
    "mk",
    "be",
  ],
  init: () => {
    set((state) => {
      const lang = localStorage.getItem("lang")

      if (lang) {
        state.current = lang
        Cookies.set("cno-language", lang, {
          expires: 365,
          path: "/",
          domain: window.location.host,
        })
      }

      if (lang === "he") {
        document.body.setAttribute("dir", "rtl")
      } else {
        document.body.setAttribute("dir", "ltr")
      }
    })
  },
  t: (key, values = {}) => {
    let result =
      get().translations?.[get().current]?.[key] ??
      get().translations?.["en"]?.[key] ??
      key

    Object.keys(values)?.map((value) => {
      result = result.replace(`{${value}}`, values[value])
    })

    result = result.replace(/&nbsp;/g, "\u00a0")
    result = result.replace(/&hellip;/g, "...")

    return result
  },
  setLanguage: (code) => {
    set((state) => {
      if (Object.keys(state.translations).indexOf(code) !== -1) {
        state.current = code
        localStorage.setItem("lang", code)
        Cookies.set("cno-language", code, {
          expires: 365,
          path: "/",
          domain: window.location.host,
        })

        if (code === "he") {
          document.body.setAttribute("dir", "rtl")
        } else {
          document.body.setAttribute("dir", "ltr")
        }
      } else {
        state.current = "en"
        localStorage.setItem("lang", "en")
      }
    })
  },
})

const useStore = create(immer(store))

export const useLocalizationStore = useStore

export const useLocalization = () => {
  const t = useStore((state) => state.t)
  const setLanguage = useStore((state) => state.setLanguage)
  const translations = useStore((state) => state.translations)
  const current = useStore((state) => state.current)
  const languages = useStore((state) => state.languages)

  return {
    t,
    setLanguage,
    translations,
    current,
    languages,
  }
}
