import clsx from "clsx"
import { publicCDN } from "config"
import { themeConfig } from "features/Theme/themeConfig"
import { isDarkMode, useThemeState } from "features/Theme/useThemeState"

export const CenteredLayout = (props) => {
  const theme = useThemeState((state) => state.theme)
  return (
    <main className={clsx("centered-layout px-2", props?.className)}>
      <div
        className="absolute inset-0 transition-all"
        style={{
          zIndex: -1,
          background: isDarkMode()
            ? "radial-gradient(circle at 50% 50%, #5e241e 0%, #100502 100%)"
            : "radial-gradient(circle at 50% 50%, #e7663c 0%, #480C02 100%)",
        }}
      />

      <div
        className="bg-cover absolute inset-0"
        style={{
          zIndex: -1,
          ...themeConfig[theme].bgImageStyle,
        }}
      />
      {props.children}
      <style jsx>{`
        .centered-layout {
          position: absolute;
          top: 0;
          width: 100%;
          min-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: white;
          background-size: cover;
          text-align: center;
        }
      `}</style>
    </main>
  )
}
