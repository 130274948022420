import { debug } from "logger"
let isProduction =
  // process.env.NEXT_PUBLIC_STAGE != "true" &&
  process.env.NODE_ENV === "production"

const logger = debug.extend("plausible")
export const plausible = (...args: any) => {
  if (!isProduction) {
    logger.apply(null, args)
    return
  }

  try {
    window.plausible(...args)
  } catch (e) {
    logger("error sending event", ...args)
  }
}
