export const color = {
    red: "rgba(143, 43, 28, 1)",
    redLight: "rgba(230, 88, 49, 1)",
    blue: "rgba(50, 132, 163, 1)",
    blueLight: "rgba(123, 202, 233, 1)",
    normie: "rgba(173, 140, 109, 1.00)",
    normieLight: "rgba(249, 215, 177, 1.00)",
    yellow: "#FFE400",
    yellowLight: "#FFF6A7",
    yellowDark: "#F3BB00",
}
