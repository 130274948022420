declare global {
    interface Window {
        gtag: any
        plausible: any
    }
}

let isProduction =
    process.env.NEXT_PUBLIC_STAGE != "true" &&
    process.env.NODE_ENV === "production"

export const GA_TRACKING_ID = isProduction
    ? "UA-26923458-7" // production key
    : "UA-162463366-2" // stage key

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    if (!isProduction) return

    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
    action,
    category = null,
    label = null,
    value = null,
}) => {
    if (!isProduction) return

    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}
