import create from "zustand"
import produce from "immer"
import { persist } from "zustand/middleware"

import axios from "axios"

import { lobbyUrl } from "../../config"
import { debug } from "logger"

import { plausible } from "plausible"
import { useThemeState } from "features/Theme/useThemeState"
import { useA11y } from "features/A11y/useA11y"
import { useLocalizationStore } from "features/Localization/useLocalization"
import { useMonitoringStore } from "hooks/useMonitoring"
import { useSoundState } from "features/Sound/useSoundState"

const logger = debug.extend("lobby")

const lobbyapi = axios.create({
  withCredentials: true,
  baseURL: lobbyUrl,
})

interface RoomData {
  roomID: string
  playerID: string
  credentials: string
  serverURL: string
}

interface Session {
  token: string
  invalidateAt: string
  tags: [{ name: string }]
}

export const getNickname = (): string | null => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("nickname")
  }
}

export const setNickname = (name: string): void => {
  if (typeof window !== "undefined") {
    localStorage.setItem("nickname", name)
  }
}

const getIframeOrigin = () => {
  return window !== window.parent

  const locationAreDisctint = window.location !== window.parent.location
  const parentOrigin = (
    (locationAreDisctint ? document.referrer : document.location) || ""
  ).toString()

  if (parentOrigin) {
    return new URL(parentOrigin).origin
  }

  const currentLocation = document.location

  if (
    currentLocation.ancestorOrigins &&
    currentLocation.ancestorOrigins.length
  ) {
    return currentLocation.ancestorOrigins[0]
  }

  return window !== window.parent
}

const onRoomEnter = (action) => {
  console.log("getIframeOrigin", getIframeOrigin())

  plausible("RoomEnter", {
    props: {
      a11y: useA11y.getState().a11yActive,
      darkmode: useThemeState.getState().darkMode,
      lang: useLocalizationStore.getState().current,
      soundNotification: useSoundState.getState().onTurnStart,
      eventSkin: useThemeState.getState().theme,
      action,
      iframe: getIframeOrigin(),
    },
  })
}

const defaultState = {
  session: null as Session | null,
  rooms: {} as Record<string, RoomData>,
}

const store = (set, get) => ({
  ...defaultState,
  getServerStatus: async () => {
    const res = await lobbyapi.get(`/status`)

    logger("server status", res.data)

    return res.data as { status: Boolean }
  },
  createRoom: async () => {
    const nickname = getNickname()

    useMonitoringStore.getState().startTimer("createRoomTimer")

    try {
      const res = await lobbyapi.post(`/room/create`, {
        nickname,
        source: window.location.host,
        gameName: "codenames-hp",
      })

      const resData = res.data
      logger("createRoom resData", resData)

      get().update((state) => {
        state.rooms[resData.room.roomID] = resData.room
      })

      onRoomEnter("create")

      useMonitoringStore.getState().stopTimer("createRoomTimer")

      return resData
    } catch (e) {
      logger("createRoom error", e)

      useMonitoringStore
        .getState()
        .stopTimer("createRoomTimer", { result: "error" })

      return null
    }
  },
  getRoom: async ({ queryKey }) => {
    // logger("getRoom start", queryKey[1])
    return lobbyapi.get(`/room/${queryKey[1]}`).then((res) => res.data)
  },
  joinRoom: async (roomID, joinToken) => {
    const nickname = getNickname()

    useMonitoringStore.getState().startTimer("joinRoomTimer")
    try {
      const res = await lobbyapi.post(`/room/${roomID}/join`, {
        nickname,

        joinToken,
      })

      const resData = res.data
      logger("joinRoom resData", resData)

      get().update((state) => {
        state.rooms[resData.room.roomID] = resData.room
      })

      onRoomEnter("join")
      useMonitoringStore.getState().stopTimer("joinRoomTimer")

      return resData
    } catch (e) {
      logger("joinRoom error", e)

      useMonitoringStore
        .getState()
        .stopTimer("joinRoomTimer", { result: "error", roomID })

      return e
    }
  },
  leaveRoom: async (roomID) => {
    const res = await lobbyapi.post(`/game/${roomID}/leave`, {
      credentials: get().rooms[roomID].credentials,
    })
    logger("leaveRoom res", res.status, res.data)

    get().update((state) => {
      delete state.rooms[roomID]
    })
  },
  deleteRoom: async (roomID) => {
    get().update((state) => {
      delete state.rooms[roomID]
    })
  },
  getBestServer: async (roomID) => {
    try {
      const res = await lobbyapi.get(`/room/${roomID}/best`)
      get().update((state) => {
        state.rooms[res.data.room.roomID] = {
          ...state.rooms[res.data.room.roomID],
          ...res.data.room,
        }
      })
      return res.data
    } catch (e) {
      logger("getBestServer error", e)
    }
  },

  checkMigration: () => {
    logger("checkMigration", typeof localStorage.getItem("gamedata"))
    if (
      localStorage.getItem("gamedata") &&
      Object.keys(get().rooms).length === 0
    ) {
      try {
        let gameData = JSON.parse(localStorage.getItem("gamedata"))
        get().update((state) => {
          let rooms = {}
          for (let roomID in gameData) {
            logger("roomID", roomID)
            try {
              rooms[roomID] = {
                roomID,
                playerID: gameData[roomID].player.playerID,
                credentials: gameData[roomID].player.credential,
                serverURL: gameData[roomID].server.url,
              }
            } catch (e) {
              logger("roomID migration error", e)
            }
          }

          state.rooms = rooms
        })
      } catch (e) {
        logger("gameData parse error", e)
      }
    }
  },
  createIntegrationRoom: async ({ queryKey }) => {
    const { integrationID, foreignID, nickname, cno } = queryKey[1]
    setNickname(nickname)

    try {
      const res = await lobbyapi.post(`/room/i/${integrationID}/${foreignID}`, {
        nickname: nickname,
        cno: cno,
        source: window.location.host,
      })

      const resData = res.data
      logger("createIntegrationRoom resData", resData)

      get().update((state) => {
        state.rooms[resData.roomID] = {
          ...state.rooms?.[resData.roomID],
          ...resData,
        }
      })

      onRoomEnter("create")

      useMonitoringStore.getState().stopTimer("createIntegrationRoomTimer")

      return resData
    } catch (e) {
      logger("createIntegrationRoom error", e)

      useMonitoringStore
        .getState()
        .stopTimer("createIntegrationRoomTimer", { result: "error" })

      throw e
    }
  },
  update: (fn) => set(produce(fn)),
})

export const useLobbyStore = create(
  persist(store, {
    name: "lobby",
    version: 1,
    migrate: (oldState, oldVersion) => {
      logger("migrate", oldState, oldVersion)
      return oldState
    },
  })
)
