export const IS_STAGE =
  (process.env.NODE_ENV === "production" &&
    process.env.NEXT_PUBLIC_STAGE == "true") ||
  process.env.NODE_ENV === "development"

export const lobbyUrl = (() => {
  if (process.env.NODE_ENV === "development") {
    // hint: you must specify local IP address of lobby server when testing on other devices (phones, tablets)
    return "http://localhost:8001"
    return "http://10.0.1.21:8001" // u rodicu
    return "http://10.0.0.8:8001" // doma
    return "http://192.168.43.145:8001" // hotspot
    return "http://10.166.6.2:8001" // kancl

    return "http://192.168.0.192:8001"
    return "https://lobby.codenames.game"
  }

  if (process.env.NEXT_PUBLIC_APP_ENV === "stage") {
    return "https://stage-lobby.codenames.game"
  }

  return "https://lobby.codenames.game"
})()

export const webURL = (() => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000"
  }

  if (process.env.NEXT_PUBLIC_STAGE == "true") {
    return "https://stage.codenames.game"
  }

  return "https://codenames.game"
})()

export const publicCDN = process.env.NEXT_PUBLIC_CDN
  ? process.env.NEXT_PUBLIC_CDN
  : ""
