import create from "zustand"
import produce from "immer"
import { persist } from "zustand/middleware"
import { nanoid } from "nanoid"
import axios from "axios"

import { debug } from "logger"
import { plausible } from "plausible"

const logger = debug.extend("monitoring")

const defaultState = {
  id: nanoid(),
  runningTimers: [],
}

const store = (set, get) => ({
  ...defaultState,
  update: (fn) => set(produce(fn)),
  sendEvent: async (name, props) => {
    plausible(name, {
      props,
    })
  },
  startTimer: (name) => {
    const timeout = setTimeout(() => {
      get().stopTimer(name)
    }, 30000)

    get().update((state) => {
      state.runningTimers.push({ name, start: Date.now(), timeout })
    })
  },
  stopTimer: (name, props = undefined) => {
    const timer = get().runningTimers.find((timer) => timer.name === name)
    if (timer) {
      get().sendEvent(timer.name, {
        duration: Date.now() - timer.start,
        ...props,
      })
      get().update((state) => {
        state.runningTimers = state.runningTimers.filter(
          (timer) => timer.name !== name
        )
      })
    }
  },
})

export const useMonitoringStore = create(
  persist(store, {
    name: "monitoring",
    version: 1,
    migrate: (oldState, oldVersion) => {
      logger("migrate", oldState, oldVersion)
      return oldState
    },
    partialize: ({ runningTimers, ...rest }) => {
      return rest
    },
  })
)
