import * as Sentry from "@sentry/nextjs"

Sentry.init({
  dsn: "https://ff4e59b0bd484348bbc431d7c8379ce2@www.sentry.oone.dev//2",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
