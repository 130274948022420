import React from "react"
import { color } from "theme"
import { isDarkMode, useThemeState } from "features/Theme/useThemeState"

export const Button: any = React.forwardRef<any, any>(
  (props: any, ref): any => {
    const {
      onClick,
      disabled,
      large,
      fontSize,
      children,
      link,
      menuOpen,
      selected,
      iconLeft,
      iconRight,
      flexGrow,
      tailwind,
      variant,
      ...rest
    } = props
    const onKeyPress = (handlerFunc) => (e) => {
      if (e.key === "Enter") {
        handlerFunc()
      }
    }

    const handleClick = (e) => {
      if (props.disabled) {
        return
      }

      onClick(e)
    }

    const classNames = [
      tailwind ? "tailwindButton shadow-bottom " : "button shadow-bottom ",
    ]
    classNames.push(tailwind)
    disabled ? classNames.push("disabled") : null
    menuOpen ? classNames.push("menuOpen") : null
    link ? classNames.push("link") : null
    selected ? classNames.push("selected") : null
    fontSize
      ? classNames.push(fontSize)
      : large
      ? classNames.push("text-2xl")
      : classNames.push("text-base")
    props.color ? classNames.push(`color-${props.color}`) : null
    flexGrow ? classNames.push(`flexGrow noWrap`) : null

    return (
      <button
        role="button"
        className={classNames.join(" ")}
        onClick={handleClick}
        {...rest}
        onKeyPress={onKeyPress(handleClick)}
        ref={ref}
      >
        {iconLeft && (
          <div className={"iconLeftWrapper"}>
            <img src={iconLeft} />
          </div>
        )}

        {children}

        {iconRight && (
          <div className={"iconRightWrapper"}>
            <img src={iconRight} />
          </div>
        )}

        <style jsx>{`
        .button {
          display: inline-block;
          position: relative;
          padding: 0.4rem ${iconRight ? "2.5rem" : "1rem"} 0.3rem
            ${iconLeft ? "2.8rem" : "1rem"};
          color: black;
          border-radius: ${large ? "1.3rem" : "1rem"};
          ${
            isDarkMode()
              ? `
                        color: rgba(255,255,255,0.72);
                        background-color: #776d46;
                        border: solid 1px #776d46;
                        border-top: solid 2px #9c957a;
                        border-bottom: solid 2px #554d31;
                    `
              : `
                        color: black;
                        background-color: ${color.yellow};
                        border: solid 1px ${color.yellow};
                        border-top: solid 2px ${color.yellowLight};
                        border-bottom: solid 2px ${color.yellowDark};
                    `
          }
          cursor: pointer;
          user-select: none;
          outline: none;
          transition: all 200ms;
          min-width: 0;
          touch-action: manipulation;
          /* ${
            variant === "condensed-portrait" ? `padding: 0.1rem 0.75rem;` : ``
          } */
        }
        @media (max-width: 500px) {
          .button {
            ${
              variant === "condensed-portrait"
                ? `
            padding: 0.2rem 0.5rem 0.1rem 0.5rem; 
            font-size: 0.825rem; 
            line-height: 0.825rem;
            // min-height: 2.4rem;
          `
                : ``
            }
          }
        }
        .tailwindButton {
          cursor: pointer;
          user-select: none;
          outline: none;
          transition: all 200ms;
          touch-action: manipulation;
          ${
            isDarkMode()
              ? `
                        color: rgba(255,255,255,0.72);
                        background-color: #776d46;
                        border: solid 1px #776d46;
                        border-top: solid 2px #9c957a;
                        border-bottom: solid 2px #554d31;
                    `
              : `
                        color: black;
                        background-color: ${color.yellow};
                        border: solid 1px ${color.yellow};
                        border-top: solid 2px ${color.yellowLight};
                        border-bottom: solid 2px ${color.yellowDark};
                    `
          }
        }
        .button:active {
          top: 0.05rem;
          box-shadow: 0 0.05rem 1px 0px rgba(0, 0, 0, 0.5);
        }
        .button:hover,
        .tailwindButton:hover {
          ${
            isDarkMode()
              ? `
                        background-color: #534c2f;
                        border: solid 1px #534c2f;
                        border-top: solid 2px #9c957a;
                        border-bottom: solid 2px #554d31;
                    `
              : `
                        background-color: #ffd300;
                        border: solid 1px #ffd300;
                        border-top: solid 2px #fff078;
                        border-bottom: solid 2px #e19708;
                    `
          }
        }
        .tailwindButton:active {
          box-shadow: 0 0.05rem 1px 0px rgba(0, 0, 0, 0.5);
        }

        .menuOpen {
          top: 0.1rem;
          box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.5);
          ${
            isDarkMode()
              ? `
                        background-color: #9c957a;
                    `
              : `
                        background-color: ${color.yellowLight};
                    `
          }
        }
        .link {
          background-color: transparent;
        }
        .selected {
          ${
            isDarkMode()
              ? `
                        background-color: #9c957a;
                        box-shadow: inset 2px 2px 0px 0px #554d31;
                        border: solid 1px #554d31;
                    `
              : `
                        background-color: #f0f0f0;
                        border: solid 1px #a0a0a0;
                        box-shadow: inset 2px 2px 0px 0px #c0c0c0;
                    `
          }
        }

        .color-beige {
          ${
            isDarkMode()
              ? `
                        background-color: #776d46;
                        border: solid 1px #776d46;
                        border-top: solid 2px #9c957a;
                        border-bottom: solid 2px #554d31;
                    `
              : `
                        background-color: #dbd4af;
                        border: solid 1px #dbd4af;
                        border-top: solid 1px #eae6d0;
                        border-bottom: solid 2px #bab495;
                    `
          }
        }
        .color-beige:hover {
          ${
            isDarkMode()
              ? `
                        background-color: #534c2f;
                        border: solid 1px #534c2f;
                        border-top: solid 2px #9c957a;
                        border-bottom: solid 2px #554d31;
                    `
              : `
                        background-color: #c4b983;
                        border-top: solid 1px #ebe6d1;
                        border-bottom: solid 2px #928a62;
                    `
          }
        }
        .color-green {
          ${
            isDarkMode()
              ? `
                        background-color: #2e5409;
                        border: solid 1px #2e5409;
                        border-top: solid 1px #6a8350;
                        border-bottom: solid 2px #203c07;
                    `
              : `
                        background-color: #6ae911;
                        border: solid 1px #6ae911;
                        border-top: solid 1px #c7f79c;
                        border-bottom: solid 2px #5fa313;
                    `
          }
        }
        .color-green:hover {
          ${
            isDarkMode()
              ? `
                        background-color: #1c3306;
                        border: solid 1px #1c3306;
                        border-top: solid 1px #50643b;
                        border-bottom: solid 2px #203c07;
                    `
              : `
                        background-color: #39db07;
                        border-top: solid 1px #adf280;
                        border-bottom: solid 2px #298f05;
                    `
          }
        }
        .color-red {
          ${
            isDarkMode()
              ? `
                        background-color: #701f0a;
                        border: solid 1px #701f0a;
                        border-top: solid 1px #975f51;
                        border-bottom: solid 2px #501607;
                    `
              : `
                        background-color: #ff881e;
                        border: solid 1px #ff881e;
                        border-top: solid 1px #ffb97b;
                        border-bottom: solid 2px #d9741a;
                    `
          }
        }
        .color-red:hover {
          ${
            isDarkMode()
              ? `
                        background-color: #581808;
                        border: solid 1px #581808;
                        border-top: solid 1px #805044;
                        border-bottom: solid 2px #501607;
                    `
              : `
                        background-color: #ff540d;
                        border-top: solid 1px #ffbc86;
                        border-bottom: solid 2px #c1410b;
                    `
          }
        }
        .color-blue {
          ${
            isDarkMode()
              ? `
                        background-color: #084c56;
                        border: solid 1px #084c56;
                        border-top: solid 1px #4e7e85;
                        border-bottom: solid 2px #06363d;
                    `
              : `
                        background-color: #44c8ff;
                        border: solid 1px #44c8ff;
                        border-top: solid 1px #91dfff;
                        border-bottom: solid 2px #3fa7d3;
                    `
          }
        }
        .color-blue:hover {
          ${
            isDarkMode()
              ? `
                        background-color: #053138;
                        border: solid 1px #053138;
                        border-top: solid 1px #3a5d63;
                        border-bottom: solid 2px #06363d;
                    `
              : `
                        background-color: #20a7ff;
                        border-top: solid 1px #98e0ff;
                        border-bottom: solid 2px #1a7cc1;
                    `
          }
        }
        .color-purple {
          ${
            isDarkMode()
              ? `
                        background-color: #910657;
                        border: solid 1px #910657;
                        border-top: solid 1px #981b7f;
                        border-bottom: solid 2px #910664;
                    `
              : `
                        background-color: #b40872;
                        border: solid 1px #b40872;
                        border-top: solid 1px #b46092;
                        border-bottom: solid 2px #71044c;
                    `
          }
        }
        .color-purple:hover {
          ${
            isDarkMode()
              ? `
                        background-color: #71043c;
                        border: solid 1px #71043c;
                        border-top: solid 1px #974171;
                        border-bottom: solid 2px #520233;
                    `
              : `
                        background-color: #a1065b;
                        border-top: solid 1px #cd65a2;
                        border-bottom: solid 2px #79044e;
                    `
          }
        }

        .disabled {
          box-shadow: 0.1rem 0.1rem 1px 0px rgba(0, 0, 0, 0.09);
          cursor: not-allowed;
          ${
            isDarkMode()
              ? `
                        background-color: #333333;
                        border: solid 1px #333333;
                        border-top: solid 1px #333333;
                        border-bottom: solid 2px #333333;
                        color: #777777;
                        `
              : `
                        background-color: #c3c3c3;
                        border: solid 1px #c3c3c3;
                        border-top: solid 1px #e6e6e6;
                        border-bottom: solid 2px #bebebe;
                        color: #888888;
                    `
          }
        }

        .noWrap {
          display: flex;

          flex: 1;
        }
        .content {
          position: relative;
        }

        .iconLeftWrapper {
          position: absolute;
          top: 0.15rem;
          left: 0.3rem;
        }
        .iconLeftWrapper img {
          width: 1.8rem;
          height: 1.8rem;
        }

        .iconRightWrapper {
          position: absolute;
          top: 0.15rem;
          right: 0.3rem;
        }
        .iconRightWrapper img {
          width: 1.8rem;
          height: 1.8rem;
        }
      `}</style>
      </button>
    )
  }
)
