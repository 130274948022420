import create from "zustand"
import { persist } from "zustand/middleware"

const store = (set) => ({
    a11yActive: false,
    toggle: () => set((state) => ({ a11yActive: !state.a11yActive })),
})

export const useA11y = create(
    persist(store, {
        name: "a11y",
    })
)
