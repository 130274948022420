import * as React from "react"
import useSound from "use-sound"
import create from "zustand"
import { persist } from "zustand/middleware"

const store = (set, get) => ({
  onTurnStart: false,
  setOnTurnStart: (val) => {
    set((state) => {
      state.onTurnStart = val
    })
  },
})

export const useSoundState = create(
  persist(store, {
    name: "sound",
    version: 0,
  })
)

export const useNotificationSound = () => {
  const onTurnStart = useSoundState((state) => state.onTurnStart)

  const [playNotification] = useSound("/sound/turn-notification.mp3", {
    volume: 1,
    interrupt: true,
  })

  const play = (force = false) => {
    if (onTurnStart || force) {
      playNotification()
    }
  }

  return play
}
